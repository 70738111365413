<template lang="pug">
el-select.ui-select.standard(
  remote
  full-info
  filterable
  clearable
  remote-show-suffix
  placeholder="Выберите клиента"
  value-key="id"
  :fit-input-width="fitInputWidth"
  :teleported="false"
  :model-value="innerModelValue"
  :remote-method="getFilteredCompanies"
  :loading="info.loading"
  @update:modelValue="onChange"
)
  template(#empty)
    span.no-data Список пуст
  template(#loading)
    span.no-data(v-if="isKostyl") Список пуст
    .loader(v-else)
      mini-loader
  template(#default)
    el-option(
      v-for="item of data"
      :key="item.id"
      :label="item.title"
      :value="item"
    )
      b {{ item.title }}
      .info(:class="!fitInputWidth && 'strict'") Email: {{ item.creatorEmail }}
      .info(:class="!fitInputWidth && 'strict'") {{ getInfoLabel(item) }}
    .footer(:class="!fitInputWidth && 'strict'") {{ getCountResults(data.length, totalResults) }} {{ data.length !== totalResults ? 'Уточните запрос, введя название, ИНН, КПП, или почту клиента.' : '' }}
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useApi, useAbort } from "@/use/api/useApi";
import { getCountResults } from "@/utils/formatter/wordDeclension";
import { getDefaultRequestCommonInfo } from "@/utils/getters/defaultRequestInfo";

import type { PropType } from "vue";
import type { RequestCommonInfo } from "@/utils/getters/defaultRequestInfo";
import type { SingleCompanyInterface } from "@/stores/manuals/ManualsInterface";
import type { ExtendedCompanyItemI } from "@/stores/auth/UserInterface";

import MiniLoader from "@/components/ui/loader/MiniLoader.vue";

export default defineComponent({
  name: "TmCompanySelector",
  methods: {
    getCountResults,
  },
  components: {
    MiniLoader,
  },
  emits: [
    'update:modelValue',
  ],
  props: {
    modelValue: {
      type: Object as PropType<SingleCompanyInterface>,
      default: () => ({})
    },
    fitInputWidth: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {

    const data = ref<ExtendedCompanyItemI[]>([])
    const info = ref<RequestCommonInfo>(getDefaultRequestCommonInfo())

    const lastQuery = ref('')
    const totalResults = ref(0);

    const isKostyl = ref(false);  // todo костыль - селектор закрывается если query пуст и options/data пусты

    const innerModelValue = computed(() => {
      return props.modelValue ? { ...props.modelValue, label: props.modelValue?.title || '' } : null
    })

    function onBeforeRequest(abort: Function) {
      if (info.value.request) info.value.request.cancel();
      info.value.loading = true;
      info.value.request = { cancel: abort };

      data.value = []
      isKostyl.value = false;
    }

    function onFinallyRequest(query: string) {
      // if если ничего не ввели, и компаний нет, чтобы селектор не закрылся, мы вместо фразы "загрузка" показываем "список пуст"
      // else если есть query или data.value.length то фраза "список пуст" появится сама

      if (!query && !data.value.length) isKostyl.value = true;
      else info.value.loading = false;

      info.value.request = null;
    }

    function getFilteredCompanies(query: string) {
      if (lastQuery.value === query && data.value.length !== 0) return;
      lastQuery.value = query;

      const { signal, abort, } = useAbort();

      onBeforeRequest(abort)

      useApi().admin.fetchManagersCompaniesByQuery(query, signal)
        .then((hydraData: any) => {
          data.value = hydraData['hydra:member']
          totalResults.value = Number(hydraData['hydra:totalItems'])
          onFinallyRequest(query)
        })
        .catch((error) => {
          if (!signal.aborted) onFinallyRequest(query)
        })
    }

    function onChange(value: any) {
      if (!value) {
        data.value = [];
        info.value = getDefaultRequestCommonInfo();
      }

      context.emit('update:modelValue', value || null)
    }

    function getInfoLabel(company: ExtendedCompanyItemI) {
      return [
        company.inn && `ИНН: ${ company.inn }`,
        company.kpp && `КПП: ${ company.kpp }`,
      ].filter(Boolean).join(', ')
    }

    return {
      data,
      info,
      innerModelValue,
      totalResults,
      isKostyl,
      onChange,
      getInfoLabel,
      getFilteredCompanies,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/elements/selector';

$inner-width: 360px;

.no-data {
  display: flex;
  color: var(--main-placeholder-color);
  margin: 12px 16px;
  font-size: 13px;
  justify-content: center;
}

.loader {
  display: flex;
  color: var(--main-color-blue);
  margin: 12px 16px;
  justify-content: center;
}

.info {
  font-size: 13px;
  font-weight: normal;
  color: var(--secondary-text-color);

  &.strict {
    width: $inner-width;
  }
}

.footer {
  padding: 8px 20px;
  overflow-wrap: break-word;
  white-space: normal;

  &.strict {
    width: $inner-width;
  }
}
</style>
